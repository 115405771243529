import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import HomePage from "./screens/Home/HomePage";
import AboutPage from "./screens/About/AboutPage";
import ContactPage from "./components/ContactUsPage/ContactPage";
import NewsAndUpdatesPage from "./screens/NewsAndUpdatesPage/NewsAndUpdatesPage";
import BlogPage from "./screens/Blog/BlogPage";
import FooterHero from "./components/Footer/footerHero";
import PastPaperPage from "./screens/TopicalPastPaper/PastPaperPageOLevel";
import PastPaperPageAsLevel from "./screens/TopicalPastPaper/PastPaperPageAsLevel";
import PastPaperPageALevel from "./screens/TopicalPastPaper/PastPaperPageALevel";
import OLevelNotesPage from "./screens/OLevel/OLevelNotesPage";
import AsLevelNotesPage from "./screens/OLevel/AsLevelNotesPage";
import ALevelNotesPage from "./screens/OLevel/ALevelNotesPage";
import CurriculumPage from "./screens/Courses/CurriculumPage";
import Navbar from "./components/Header/Navbar";
import MyCourses from "./screens/MyCourses/MyCourses";
import AdminMain from "./screens/Admin/AdminMain";
import NewsAndEventsPage from "./screens/NewsAndEventsPage/NewsAndEventsPage";
import CommonCalls from "./components/CommonAPICalls/CommonCalls";
import { fetchUserDetails, logout } from "./store/actions/userActions";
import ScrollToTop from "./ScrollToTop";
import PaymetForm from "./screens/PaymetForm";
import DetailViewSystemCourses from "./components/LMS Portal/SystemCourses/DetailViewSystemCourses";
import SystemExamsDetailView from "./components/LMS Portal/SystemExams/SystemExamsDetailView";
import SystemNotesDetailView from "./components/LMS Portal/SystemNotes/SystemNotesDetailView";
import SystemOnlineClassesDetailView from "./components/LMS Portal/SystemOnlineClasses/SystemOnlineClassesDetailView";
import SystemTopicalPastPapersDetailView from "./components/LMS Portal/SystemTopicalPastPapers/SystemTopicalPastPapersDetailView";
import DetailViewSystemUser from "./components/LMS Portal/User/DetailViewSystemUser";
import SystemCampusesDetailView from "./components/LMS Portal/SystemCampuses/SystemCampusesDetailView";
import ThankYouPage from "./screens/ThankYouPage/ThankYouPage";
import VideoPlayer from "./screens/VideoPlayer";
import SystemDaysDetailView from "./components/LMS Portal/SystemDays/SystemDaysDetailView";
import NotFound from "./screens/NotFound";

const App = () => {
  const [checkStatus, setCheckStatus] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const handleRightClick = (event) => {
      event.preventDefault(); 
    };
    document.addEventListener('contextmenu', handleRightClick);
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []); 
  
  useEffect(() => {
    const handleKeyDown = (event) => {
        if (
            (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J' || event.key === 'C')) ||
            (event.ctrlKey && event.key === 'U') ||
            event.key === 'F12' ||
            (event.ctrlKey && (event.key === 'S' || event.key === 'P')) 
        ) {
            event.preventDefault();
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, []);


  useEffect(() => {
    const checkTokenStatus = async () => {
      dispatch(fetchUserDetails());

    }

    if (!checkStatus) {
      if (JSON.parse(localStorage.getItem("userInfo"))?.user_id) {
        checkTokenStatus()
      }
      else {
        localStorage.removeItem("userInfo");
      }
      setCheckStatus(true)
    }

  }, [])

  const ComonWrapperComponents = ({ children }) => {
    return (
      <>
        <Navbar />
        {children}
        <FooterHero />
      </>
    )
  }
  return ( 
    <Router>
    <ScrollToTop />
    <Routes>
      <Route path='/' element={<ComonWrapperComponents><HomePage /></ComonWrapperComponents>} />
      <Route path='/about' element={<ComonWrapperComponents><AboutPage /></ComonWrapperComponents>} />
      <Route path='/topical-past-papers/o-level' element={<ComonWrapperComponents><PastPaperPage /></ComonWrapperComponents>} />
      <Route path='/topical-past-papers/as-level' element={<ComonWrapperComponents ><PastPaperPageAsLevel /></ComonWrapperComponents>} />
      <Route path='/topical-past-papers/a-level' element={<ComonWrapperComponents ><PastPaperPageALevel /></ComonWrapperComponents>} />
      <Route path='/notes/o-level' element={<ComonWrapperComponents ><OLevelNotesPage /></ComonWrapperComponents>} />
      <Route path='/notes/as-level' element={<ComonWrapperComponents ><AsLevelNotesPage /></ComonWrapperComponents>} />
      <Route path='/notes/a-level' element={<ComonWrapperComponents ><ALevelNotesPage /></ComonWrapperComponents>} />
      <Route path='/courses' element={<ComonWrapperComponents ><MyCourses /></ComonWrapperComponents>} />
      <Route path='/courses/course-preview/:id' element={<ComonWrapperComponents >< CurriculumPage /></ComonWrapperComponents>} />

      <Route path='/contact' element={<ComonWrapperComponents ><ContactPage /></ComonWrapperComponents>} />
      <Route path='/thank-you' element={<ComonWrapperComponents ><ThankYouPage /></ComonWrapperComponents>} />
      <Route path='/news' element={<ComonWrapperComponents ><NewsAndUpdatesPage /></ComonWrapperComponents>} />
      <Route path='/newsinner' element={<ComonWrapperComponents ><BlogPage /></ComonWrapperComponents>} />
      <Route path='/news-and-events' element={<NewsAndEventsPage />} />
      <Route path='/portal' element={<AdminMain />} />
      <Route path='/payment-gateway' element={<ComonWrapperComponents ><PaymetForm /></ComonWrapperComponents>} /> 
      <Route path="*" element={<ComonWrapperComponents ><NotFound /></ComonWrapperComponents>} />

      {/* admin Portal */}
      <Route path='/portal/system-users' element={<AdminMain ><DetailViewSystemUser /></AdminMain>} />
      <Route path='/portal/system-courses' element={<AdminMain selctedTabNumber={1}><DetailViewSystemCourses /></AdminMain>} />
      <Route path='/portal/system-exams' element={<AdminMain selctedTabNumber={5}><SystemExamsDetailView /></AdminMain>} />
      <Route path='/portal/system-notes' element={<AdminMain selctedTabNumber={4}><SystemNotesDetailView /></AdminMain>} />
      <Route path='/portal/system-online-classes' element={<AdminMain selctedTabNumber={10}><SystemOnlineClassesDetailView /></AdminMain>} />
      <Route path='/portal/system-past-papers' element={<AdminMain selctedTabNumber={3}><SystemTopicalPastPapersDetailView /></AdminMain>} />
      <Route path='/portal/system-campuses' element={<AdminMain selctedTabNumber={9}><SystemCampusesDetailView /></AdminMain>} />
      <Route path='/portal/system-days' element={<AdminMain selctedTabNumber={7}><SystemDaysDetailView /></AdminMain>} />
    </Routes>
  </Router>
  );
}

export default App; 