import React from 'react'
import ThankyouMessage from '../../components/ThankYouMessage/ThankyouMessage'

const ThankYouPage = () => {
    return (
        <div>
            <ThankyouMessage />
        </div>
    )
}

export default ThankYouPage