export const getA2CourseThumbnail = (courseId,defaulImg="/images/courses-box-banner1.png") => {
    let imgUri = defaulImg;
  
    switch (courseId) {
      case 55:
        imgUri = "/images/creatives/A2Coursethumbs/1-energetics.jpg";
        break;
      case 56:
        imgUri = "/images/creatives/A2Coursethumbs/2-ionic.jpg";
        break;
      case 57:
        imgUri = "/images/creatives/A2Coursethumbs/3-electrochemistry.jpg";
        break;
      case 58:
        imgUri = "/images/creatives/A2Coursethumbs/4-reaction.jpg";
        break;
      case 71:
        imgUri = "/images/creatives/A2Coursethumbs/5-organic.jpg";
        break;
      case 72:
        imgUri = "/images/creatives/A2Coursethumbs/7-transition.jpg";
        break;
      case 73:
        imgUri = "/images/creatives/A2Coursethumbs/6-paper.jpg";
        break;
        // AS courses check
        case 59:
          imgUri = "/images/creatives/AsCourseThumbs/atomicstructure.jpg";
          break;
        case 60:
          imgUri = "/images/creatives/AsCourseThumbs/chemicalbonding.jpg";
          break;
        case 62:
          imgUri = "/images/creatives/AsCourseThumbs/moles.jpg";
          break;
        case 74:
          imgUri = "/images/creatives/AsCourseThumbs/stateofmatter.jpg";
          break;
        // case 75:
        //   imgUri = "/images/creatives/AsCourseThumbs/redoxreaction.jpg";
        //   break;
        // case  76:
        //   imgUri = "/images/creatives/AsCourseThumbs/reactionkinetics.jpg";
        //   break;
        case 77:
          imgUri = "/images/creatives/AsCourseThumbs/energetics.jpg";
          break;
        case 78:
          imgUri = "/images/creatives/AsCourseThumbs/chemicalequllibrium.jpg";
          break;
        case 79:
          imgUri = "/images/creatives/AsCourseThumbs/preodicinorganicchemistry.jpg";
          break;
        case 80:
          imgUri = "/images/creatives/AsCourseThumbs/group2.jpg";
          break;
        case 81:
          imgUri = "/images/creatives/AsCourseThumbs/group71inorganicchemistry.jpg";
          break;
        case 82:
          imgUri = "/images/creatives/AsCourseThumbs/nitrogenandsulphur.jpg";
          break;
        case 83:
          imgUri = "/images/creatives/AsCourseThumbs/organicandanalyticschemistry.jpg";
          break;

          
        // O level courses check
        case 49:
          imgUri = "/images/creatives/OCoursethumbs/atomicastucture.jpg";
          break;
        case 50:
          imgUri = "/images/creatives/OCoursethumbs/elementmistureandcompounds.jpg";
          break;
        case 51:
          imgUri = "/images/creatives/OCoursethumbs/chemicalbonding.jpg";
          break;
        case 54:
          imgUri = "/images/creatives/OCoursethumbs/stateofmatter.jpg";
          break;
        case 64:
          imgUri = "/images/creatives/OCoursethumbs/moleandstoridicity.jpg";
          break;
        case 84:
          imgUri = "/images/creatives/OCoursethumbs/olevelredoxreaction.jpg";
          break;
        case 85:
          imgUri = "/images/creatives/OCoursethumbs/metals.jpg";
          break;
        case 86:
          imgUri = "/images/creatives/OCoursethumbs/acidbase.jpg";
          break;
        case 87:
          imgUri = "/images/creatives/OCoursethumbs/electrolysis.jpg";
          break;
        case 88:
          imgUri = "/images/creatives/OCoursethumbs/energetics.jpg";
          break;
        case 89:
          imgUri = "/images/creatives/OCoursethumbs/rateofreaction.jpg";
          break;
        case 90:
          imgUri = "/images/creatives/OCoursethumbs/reversiblereaction.jpg";
          break;
        case 91:
          imgUri = "/images/creatives/OCoursethumbs/organicchemistry.jpg";
          break;
        case 92:
          imgUri = "/images/creatives/OCoursethumbs/experimentalchemistry.jpg";
          break;
        case 93:
          imgUri = "/images/creatives/OCoursethumbs/periodictable.jpg";
          break;
        case 94:
          imgUri = "/images/creatives/OCoursethumbs/chemistryofenviournment.jpg";
          break;
        case 95:
          imgUri = "/images/creatives/OCoursethumbs/hydrogenoxygenfuel.jpg";
          break;

        // Course pack courses check
        case 98:
          imgUri = "/images/creatives/completeCourseThumbnails/olevel.jpg";
          break;
          case 99:
            imgUri = "/images/creatives/completeCourseThumbnails/aslevel.jpg";
            break;
        case 100:
          imgUri = "/images/creatives/completeCourseThumbnails/a2level.jpg";
          break;

      default:
        imgUri =defaulImg;
    }
  
    return imgUri;
  };