import React, { useEffect, useState } from 'react';
import Aboutus from '../../components/AboutUs/aboutUs'; 
import HomeHero from '../../components/HomeHero/HomeHero'; 
import StartToSuccessComponent from '../../components/StartToSuccess/StartToSuccessComponent ';
import CourseCard from '../../components/CourseCard/CourseCard';
import ArticlesSlider from '../../components/ArticlesSlider/ArticlesSlider';
import TestimonialForm from '../../components/TestimonialForm/TestimonialForm';
import NewModal from '../../components/NewModal/NewModal';  
import Modal from '../../components/Model/Modal';



const HomePage = () => {
  const [showPlanerPopup, setShowPlanerPopup] = useState(false)
  
    useEffect(()=>{
      setTimeout(()=>{
        setShowPlanerPopup(true)
      },5000)
    },[])
  return (
    <div> 
        <Modal status={showPlanerPopup} closeModal={()=>{setShowPlanerPopup(false)}}>
          <div className="d-flex flex-column align-items-center justify-content-center py-2 pt-lg-2 pt-5" style={{position:"relative"}}>
            {/* <img src={window.innerWidth>= 993?"/images/creatives/planer.png":"/images/creatives/planer-mob.jpg"} alt="planer thumbnail" style={{height:"60vh",maxWidth:"100vw"}}/> */}
            {/* <button className="connect-button" onClick={()=>{window.open("https://drive.google.com/file/d/1o12Wqt1gcx3SSLY3AsnWN4c2RAcWVHxj/view","blank")}}>Download</button> */}
            <img src={window.innerWidth>= 993?"/images/creatives/online-classes-popup.jpg":"/images/creatives/online-classes-popup.jpg"} alt="planer thumbnail" style={{height:"60vh",maxWidth:"100vw"}}/>
            <button className="connect-button" onClick={()=>{window.open("https://forms.gle/7nqCJAQP6Y6VfkRj7","blank")}}>Register Now</button>
            <h5 style={{color:'white',position:"absolute",top:"0%",right:"10%",cursor:"pointer"}} onClick={()=>{setShowPlanerPopup(false)}}>X</h5>
          </div>
        </Modal>
      <HomeHero/>
      {/* <NewModal />   */} 
      <Aboutus />
      <div style={{backgroundImage:"url('/images/creatives/home-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
      <CourseCard/>
      <StartToSuccessComponent />  
      {/* <ArticlesSlider/>  */}
      <TestimonialForm/>
      </div>
    </div>
  )
}

export default HomePage;