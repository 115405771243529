import React,{useState,useEffect} from 'react'
import Courses from '../../components/Courses/Courses';
import CourseHero from '../../components/Courses/CoursesHero';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from '../../components/CommonAPICalls/Settings';
import Loader from '../../components/UI Helpers/Simple Loading/Loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Modal from '../../components/Model/Modal';

const MyCourses = () => {
    const [currentCourseType , setCurrentCourseType] = useState((localStorage.getItem("currentCourseCoursePage") ?? Settings.enumTypes[0]?.value))
    const [courses,setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const [error,setError] = useState(false)
    const userLogin = useSelector((state)=>state.userLogin).userInfo
    const { pathname } = useLocation();
    const [showPlanerPopup, setShowPlanerPopup] = useState(false)
           
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //       setShowPlanerPopup(true);
    //     }, 5000);
      
    //     return () => clearTimeout(timer);
    //   }, [currentCourseType]);
      
    
      useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
      }, [pathname]);

    const apiCall = new CommonCalls();


    const getCourseByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getSystemCoursesByType(currentCourseType,userLogin?.enrolled_courses);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }
    
  

    useEffect(()=>{
        const getApiData = async()=>{
            const coursesResponse = await getCourseByTypes()
            if(!error)
            {
                setCourses(coursesResponse)
            }
        }

        getApiData()
    },[currentCourseType])

    return (
        <div>
            {
                (currentCourseType==1||currentCourseType==2)
                &&
                <Modal status={showPlanerPopup} closeModal={()=>{setShowPlanerPopup(false)}}>
                <div className="d-flex flex-column align-items-center justify-content-center py-2 pt-lg-2 pt-5" style={{position:"relative"}}>
                    {
                        currentCourseType==1?
                        <img src={"/images/creatives/o-level-workshop-popup.jpg"} alt="planer thumbnail" style={{height:"60vh",maxWidth:"100vw"}}/>
                        :
                        <img src={"/images/creatives/as-level-workshop-popup.jpg"} alt="planer thumbnail" style={{height:"60vh",maxWidth:"100vw"}}/>
                            
                    }
                    <button className="connect-button" onClick={()=>{window.open("https://forms.gle/7nqCJAQP6Y6VfkRj7","blank")}}>Register Now</button>
                    <h5 style={{color:'white',position:"absolute",top:"0%",right:"10%",cursor:"pointer"}} onClick={()=>{setShowPlanerPopup(false)}}>X</h5>
                </div>
                </Modal>
            }
            <CourseHero 
                selectedCourseType={setCurrentCourseType}
            />
            {
                loading ?
                <div className='py-5 my-5 my-lg-3 py-lg-3'>
                    <Loader/>
                </div>
                :
                <>
                    {
                        courses.length>0?
                        <Courses 
                            typeSpecificCourse={courses}
                        />

                        :
                        <h3 className="text-center my-5 pb-5 pt-5 pt-lg-0">No Courses Found Under This Category</h3>
                    }
                </>
                    
            }
        </div>
    )
}

export default MyCourses