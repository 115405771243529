import React,{useState,useEffect} from 'react';
import PastPapers from '../../components/PastPapersSection/PastPapers';
import PlainHero from '../../components/PlainHero/PlainHero';
import ContactForm from "../../components/TestimonialForm/Contact Form/Contactform"
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from "../../components/CommonAPICalls/Settings";
import Loader from "../../components/UI Helpers/Simple Loading/Loader";
import Modal from '../../components/Model/Modal';


const papers1 = [
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers2 = [
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers3 = [
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "AS Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];

const PastPaperPageAsLevel = () => {
    const [pastPapers,setPastPapers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [showPlanerPopup, setShowPlanerPopup] = useState(false)
       
    // useEffect(()=>{
    //   setTimeout(()=>{
    //     setShowPlanerPopup(true)
    //   },5000)
    // },[])

    const apiCall = new CommonCalls();
    const getPastPapersByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getTropicalPastPapersByType(Settings.enumTypes[1]?.value);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }

    useEffect(()=>{
        const getApiData = async()=>{
            const papersResponse = await getPastPapersByTypes()
            if(!error)
            {
                setPastPapers(papersResponse)
            }
        }

        getApiData()
    },[])
  
  return (
    <div>
      {/* <NavbarCS /> */}
       <Modal status={showPlanerPopup} closeModal={()=>{setShowPlanerPopup(false)}}>
          <div className="d-flex flex-column align-items-center justify-content-center py-2 pt-lg-2 pt-5" style={{position:"relative"}}>
            <img src={window.innerWidth>= 993?"/images/creatives/yearly-pp-popup.jpg":"/images/creatives/yearly-pp-popup.jpg"} alt="planer thumbnail" style={{height:"60vh",maxWidth:"100vw"}}/>
            <button className="connect-button" onClick={()=>{window.open("https://forms.gle/7nqCJAQP6Y6VfkRj7","blank")}}>Register Now</button>
            <h5 style={{color:'white',position:"absolute",top:"0%",right:"10%",cursor:"pointer"}} onClick={()=>{setShowPlanerPopup(false)}}>X</h5>
          </div>
        </Modal>
      <PlainHero
        heading="Topical Past Papers"
        subHeading=""
        content="Our topical past papers are well organized to ensure effective practice. Each question includes a clear reference to the year it was taken from. Students can easily track their progress and focus on their weak areas by practicing questions from specific chapters. "
      />
      <div
        style={{
          backgroundImage: "url('/images/creatives/tropical-composite-bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          pastPapers && (
            <PastPapers
              pastPapersContent={pastPapers}
              title="AS level Topical Past Papers"
              content={"As you'll step from O levels into AS level, you'll find a significant increase in the difficulty level. The examiners expect you to understand various question patterns and how to answer them correctly using the given command words. Practice, practice and practice should be your motto so that you can eventually learn to construct high quality answers. "}
              bgImg={"bg1"}
            />
          )
        )}
        <ContactForm />
      </div>
    </div>
  );
}

export default PastPaperPageAsLevel