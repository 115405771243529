import React, { useEffect, useState } from 'react'
import "./CartPopup.css"
import { useDispatch, useSelector } from 'react-redux'
import Settings from '../../CommonAPICalls/Settings'
import { removeFromCart } from '../../../store/actions/cartActions'
import { useNavigate } from 'react-router-dom'
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities'
import { getA2CourseThumbnail } from '../../UI Helpers/CourseThumnailMapper/A2CourseThumnailMapper'

function CartPopup({closeModal}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartItems = useSelector((state)=> state.cartItems)
    const userLogin = useSelector((state)=>state.userLogin)
    const [btnDisableState, setBtnDisableState] = useState(true)

    const checkBtnState = ()=>{
        if(cartItems.length == 0 && !userLogin?.userInfo?.token)
        {
            setBtnDisableState(true)
        }
        else if(userLogin?.userInfo?.token)
        {
            setBtnDisableState(false)
        }
    }
    const handleCheckout = ()=>{
        if(cartItems.length == 0)
        {
            showAlertMessage("No items Selected!","fail")
            // closeModal()
        }
        else if(!userLogin?.userInfo?.token)
        {
            showAlertMessage("Please create a user or login then proceed to checkout","fail")
            // closeModal()
        }
        // else if(btnDisableState)
        // {
        //     showAlertMessage("Please create a user or login then proceed to checkout","fail")
        //     closeModal()
        // }
        else if(userLogin?.userInfo?.token)
        {
            navigate("/payment-gateway")
            closeModal()
        }
    }
    useEffect(()=>{
        checkBtnState()
    },[])
  return (
    <div className='CartPopup-container container'>
        <div className='main-container '>
            <div className='grey-header d-flex align-items-center justify-content-between p-lg-5 p-4 mb-4 flex-wrap'>
                <div className='left-content d-flex align-items-center gap-2'>
                    <h2 className='h1_main mb-0'>Cart</h2>
                    <p className='mb-0 body-paragraph' style={{color:"grey"}}>{`(${cartItems?.length} items)`}</p>
                </div>

                <div>
                    <img onClick={closeModal}  alt='close icon' src='/images/icons/close-icon-dark.svg' className='cross-icon'/>
                </div>

            </div>

            <div className='cart-slider px-lg-5 px-4 gap-4 d-flex flex-column'>
                {
                    cartItems.map((item)=>{
                        return(
                            <div className='card-box '>
                                <div className='row  '>
                                    <div className='col-lg-4'>
                                        <img className='img-fluid w-100 w-lg-auto h-100' src={`${getA2CourseThumbnail(item?.course_id)}`} alt='cart '/>
                                    </div>
                                    <div className='col-lg-6 h-100'>
                                        <div className='cart-content text-start d-flex flex-column justify-content-evenly py-3 h-auto'>
                                            <p className='sub-heading red-text'>{Settings.enumTypes.find(id => id.value === item?.type_id)?.label}</p>
                                            <h2 className='h1_main mb-3'>{item?.title}</h2>
                                            <p className='body-paragraph' >{item?.description}</p>
                                            
                                            {/* {
                                                item.level
                                                &&
                                                <div className='d-flex flex-column flex-lg-row icons-container align-items-start align-items-lg-center gap-4 '> 
                                                    <div className='lessons-box d-flex align-items-center gap-3'>
                                                        <img className='box-icons' alt='stack icon' src='/images/icons/difficulty-cart-icon.svg' />
                                                        <p className='body-paragraph mb-0' >{Settings.enumCourseLevels.find(id => id.value === item?.level)?.label}</p>
                                                    </div>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className='col-lg-2 text-end'>
                                        <button className='delete-btn-cart pt-3' onClick={()=>{dispatch(removeFromCart(item._id))}}>
                                            <img  alt='delete icon' src='/images/icons/delete-icon-red.svg' className='delete-icon'/>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>

            <div className='btn-container px-lg-5 px-4 py-4'>
                <button className='genral-btn'
                    onClick={handleCheckout}
                >
                    Check Out
                </button>
            </div>

        </div>
    </div>
  )
}

export default CartPopup