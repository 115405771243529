import React from 'react';
import './ThankyouMessage.css';

const ThankyouMessage = () => {
    return (
        <div className="thank-you-container">
            <div className='content'>
                <h1 className="h2_main">Thank You for Getting in Touch!</h1>
                <p className="thank-you-message">
                    Your interest means a lot to us. We’ve successfully received your details, and one of our team members will reach out to you shortly.
                </p>
                <h2 className="h2_sub">What Happens Next?</h2>
                <ul className="next-steps">
                    <li>
                        <strong>📞 Personalized Response:</strong> Expect an email or a call soon with more information on how we can assist you in achieving your academic goals.
                    </li>
                    <li>
                        <strong>📚 Resources & Updates:</strong> Keep an eye on your inbox for free study guides, updates on webinars, and special offers tailored just for you.
                    </li>
                    <li>
                        <strong>🌐 Stay Connected:</strong> In the meantime, feel free to explore our YouTube channel for insightful videos and exam preparation tips.
                    </li>
                    <li>
                        If you have any urgent questions, don’t hesitate to reach out at <a href="mailto:chem.moosakhan@gmail.com">chem.moosakhan@gmail.com</a>. We’re here to help!
                    </li>
                </ul>
                <div className="thank-you-button-container">
                    <a href="/" className="connect-button">Go Back Home</a>
                </div>
            </div>
        </div>
    );
};

export default ThankyouMessage;
