import React, { useState, useEffect } from 'react';
import './Signup.css';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Loader from '../../components/UI Helpers/Simple Loading/Loader';
import { useDispatch } from 'react-redux';
import { login } from "../../store/actions/userActions"
import { handleLoginSuccess } from "../../store/actions/userActions"
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import FieldsRenderer from '../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import { validateForm } from '../UI Helpers/HelperFunctions/Helper';
import Settings from '../CommonAPICalls/Settings';


const Signup = ({ closeModal, openLogin }) => {
  const [formInputData, setFormInputData] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [saveBtn, setSaveBtn] = useState(false)
  const [campusesList, setCampusesList] = useState([]);
  const dispatch = useDispatch()

  const apiCall = new CommonCalls();

  const getUserCampusOptions = async () => {
    const apiResponse = await apiCall.getSystemCampuses();
    if (apiResponse) {
      setCampusesList(apiResponse?.data?.map((item) => ({
        value: item?.campus_id,
        label: item?.campus_name
      })));
    }
  };

  useEffect(() => {
    getUserCampusOptions();
  }, []);

  const inputsFieldAr = [
    {
      label: "Full Name",
      className: "col-lg-12",
      inputType: "text",
      name: "fname",
      id: "fname",
      required: true
    },
    {
      label: "Phone Number",
      className: "col-lg-12",
      inputType: "phone",
      name: "phone_number",
      id: "phone_number",
      required: true
    },
    {
      label: "Category",
      className: "col-lg-12",
      options: Settings.enumTypes,
      inputType: "dropdown",
      name: "student_category",
      id: "student_category",
      required: true
    },
    {
      label: "Student Campus",
      className: "col-lg-12",
      options: campusesList,
      inputType: "dropdown",
      name: "student_campus_id",
      id: "student_campus_id",
      required: true
    },
    {
      label: "Email",
      className: "col-lg-12",
      inputType: "text",
      name: "email",
      id: "email",
      required: true
    },
    {
      label: "Password",
      className: "col-lg-12",
      inputType: "password",
      name: "password",
      id: "password",
      required: true
    },
    // {
    //   label: "Profile Image",
    //   className: "col-lg-12",
    //   inputType: "uploader",
    //   name: "user_profile_image",
    //   id: "user_profile_image",
    // },
  ]

  const registerUser = async () => {
    setLoading(true)
 
    const userData = {
      "email": formInputData.email,
      "password": formInputData.password,
      "phone_number": formInputData.phone_number,
      "student_category": formInputData.student_category,
      "student_campus_id": formInputData.student_campus_id,
      "full_name": formInputData.fname,
    }
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setLoading(false)
      setSaveBtn(false)
      return
    }
    const response = await apiCall.registerUserToDB(userData);
    if (response?.status === 201) {
      setError(false)
      if(formInputData?.user_profile_image)
      {
        const imgResponse = await apiCall.addUserProfileImg({user_profile_image:formInputData?.user_profile_image},response?.user_details?.user_id,response?.token);
        if(imgResponse?.status == 201)
        {
        }
        else if(response?.length === 0)
        {
          showAlertMessage(response?.message,"fail")
          setError("Could not send data to server")
          return 
        }
      }

      setSaveBtn(false)
      setLoading(false)
      showAlertMessage(`${response?.message}, Please login your account`, "success")
      openLogin()
      return response
    }
    else {
      setLoading(false)
      setSaveBtn(false)
      showAlertMessage(response?.message, "fail")
      setError("Could not send data to server")
      return
    }
  }

  return (
    <div className="container ">
      <div className={`row flex-row-reverse no-gutters `}>
        <div className="col-lg-6 create-accout-section">
          <div className="row">
            <div className="col-12 ">
              <div className=" img-moda text-end">
                <img
                  onClick={closeModal}
                  src="/icons/icon-cross.png"
                  alt="cross-icon"
                  class="icon-cross"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="login-box ">
                <h2 className="h1_main">Create an account</h2>

                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    setSaveBtn(true)
                    registerUser();
                  }}
                >
                  <FieldsRenderer
                    fieldsArray={inputsFieldAr}
                    updateState={setFormInputData}
                    currentState={formInputData}
                  />

                  <button type="submit" className="connect-button w-100" disabled={saveBtn}>

                    <div><span> Sign Up </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 welcome-section d-none d-lg-flex">

          <div className="signup-box">
            <h2>Welcome Back</h2>
            <p>
              Log in into your account
            </p>
            <button className="btn btn-outline-light" onClick={openLogin}>Log In</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;